<template>
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link href="/" class="brand-logo">
      <ff-logo class="header-logo" />
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Modules.Page.Message.You are not authorized!') }} 🔐
        </h2>
        <p class="mb-2">
          {{ $t('Modules.Page.Message.You do not have permission to access this page') }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >{{ $t('Modules.Page.Label.Back to Home') }}</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import * as constants from '@/constants'
import FfLogo from "@core/layouts/components/ff-logo.vue";

export default {
  components: {
    BLink, BImg, BButton, FfLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem(constants.STORAGE_KEY)) ? JSON.parse(localStorage.getItem(constants.STORAGE_KEY)).user : null
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
